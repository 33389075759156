/*global gtag*/
import React from 'react';

import Container from 'react-bootstrap/Container'


class Subscribe extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  handleSubmit = event => {
    if (document.location.hostname.search("www.essentialmathfordatascience.com") !== -1) {
      gtag('event', 'click', {
        'event_category': 'subscribe',
        'event_label': '',
        'value': ''
      });
    }
  };

  render() {
    return (
      <div className="subscribe">
        <div className="subscribe-background"></div>
      <Container style={{width: '60%'}}>
        <div id="mc_embed_signup">
          <form
            action="https://github.us20.list-manage.com/subscribe/post?u=df37f925c169d6d2b29f75eef&amp;id=ea1286593f&SIGNUP=landing"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            onSubmit={this.handleSubmit}
            noValidate>
            <div className='subscribe-container'>
              <div id="mc_embed_signup_scroll" className="subscribe-email-input-container">
                <label htmlFor="mce-EMAIL" className="subscribe-title">
                  <h2 style={{color: 'white'}}>Get the latest info about the book.</h2>
                </label>
                <div className="subscribe-email-input-container">
                  <input type="email" defaultValue="" name="EMAIL" className="email subscribe-email" id="mce-EMAIL" placeholder="email address" required />
                </div>
                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                  <input type="text" name="b_df37f925c169d6d2b29f75eef_ea1286593f" tabIndex="-1" defaultValue="" />
                </div>
                <div className="clear subscribe-input-container">
                  <input value="Subscribe" type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button subscribe-input" />
                </div>
                </div>
              </div>
          </form>
        </div>
      </Container>
      </div>
    );
  }
}



export default Subscribe;
