import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class WhyMath extends React.Component {
  render() {
    return (
      <Container className="why-math">
        <h2 className="underline">Do you need math?</h2>
        <Row className="why-math-description">
          <Col xs={12} sm={6}>
            <p>The great libraries in the data science and machine learning ecosystem allow you to
            dive into the field without knowing much about the theory. I think that this <b>top-down approach</b> is a great
            way to start: take real data and run some algorithms.</p>

            <p>Then, a lot of questions arise like:
            "Why don't I get the expected prediction performance on this dataset?", "How can I adapt
            this algorithm to my specific use case?" At this point, the lack of theory can be
            a <b>limit to your skill growth</b>. The solution is to dive a bit more into the theory and
            sharpen your understanding.</p>

            <p>In this book, I'll introduce you to math concepts specifically targeted at increasing
            your understanding in data science and machine learning. I can assure you that
            even a preliminary exposition to <b>math thinking</b> will clear your vision of the field.</p>

            <p>Because the audience of this book is people without a deep math background (e.g. junior data scientists, developers
            in a career move to data science), the approach is: <b>no-jargon</b> and <b>more insights</b>. You'll just need
            high school math notions (even rusted).</p>
          </Col>
          <Col>
            <p>A preliminary exposition to <b>Python</b> (ideally with the library Numpy) is something that will allow you to
            make the most of this book. The level of programming skill needed is a bit higher in the hands-on projects
            at the end of each chapter.</p>
            <p>Here are a few examples of why <b>math are useful</b> in the context of data science and machine learning:</p>
            <p>- Understand the differences between algorithms and which tool is best in what situation.</p>
            <p>- Make the most of machine learning libraries like Sklearn and be able to understand the documentation.</p>
            <p>- Avoid misinterpreting the results of your analyses.</p>
            <p>- Debug models that are not converging by diagnosing the issue.</p>
            <p>- Create custom prediction functions and cost functions allowing to you to adapt the algorithm to the problem you want to solve.</p>
            <p>... and a lot more!</p>
          </Col>
        </Row>
      </Container>
    );
  }
}



export default WhyMath;
