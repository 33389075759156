import React from 'react';

import MyVerticallyCenteredModal from './LookInside.js'

import Container from 'react-bootstrap/Container'
import cover from '../assets/images/cover.jpg';


function Cover() {


    const [modalShow, setModalShow] = React.useState(false);

    return (

      <Container className="cover text-center" style={{cursor: 'pointer'}}>
        <div className="text-center look-inside" onClick={() => setModalShow(true)}>Look inside!</div>
        <img className="cover-shadow" src={cover} width="100%" onClick={() => setModalShow(true)} alt="cover" />

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

      </Container>
    );
  }



export default Cover;
