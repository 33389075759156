import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Container from 'react-bootstrap/Container'



class Satisfied extends React.Component {
  render() {
    return (
	      	<Container className="satisfied">
		        <h5 className="satisfied-title text-center">SATISFACTION GUARANTEED</h5>
		        <div>
	      		<p>My content has helped thousands of people to improve their skill and knowledge
	      		in data science and machine learning.</p>
	      		<p>However, if the book is not a good fit for you, give me your feedback within
	      		30 days of your purchase and you'll get a refund.</p>
	      		</div>
			</Container>

    );
  }
}


export default Satisfied;
