import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import neuralNet from '../assets/images/icons/neural_net_icon.svg';


class ForWho extends React.Component {
  render() {
    return (
      <Container className="for-who">
        <Row>

          <Col className="for-who-element" xs={12} sm={4}>
            <div className="text-center">
              <svg width="3em" height="3em" viewBox="0 0 16 16" className="bi bi-code" fill="#565872" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M5.854 4.146a.5.5 0 0 1 0 .708L2.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm4.292 0a.5.5 0 0 0 0 .708L13.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z"/>
              </svg>
            </div>

            <div className="title-feature text-center">For Developers</div>

            <div className="text-center">
               Learn the math with code examples. Get your hands dirty and get more insights.
            </div>
          </Col>

          <Col className="for-who-element" xs={12} sm={4}>
            <div className="text-center">
              <img src={neuralNet} alt="" width="60px" title="neuralNet" />
            </div>
            <div className="title-feature text-center">For Data Scientists</div>


              <div className="text-center">
                Boost your DS and ML skills as a practitioner by knowing better what's under the hood.
              </div>
          </Col>
          <Col className="for-who-element" xs={12} sm={4}>
            <div className="text-center">
              <svg width="3em" height="3em" viewBox="0 0 791.803 791.803" fill="#565872">
                <g>
                  <path d="M395.904,475.016l-236.203-72.7v71.524v49.896c0,56.133,105.787,101.644,236.274,101.644
                    c130.48,0,236.285-45.511,236.285-101.644c0-0.442-0.145-0.886-0.154-1.318V402.316L395.904,475.016z"/>
                  <path d="M0,318.734l84.383,30.185l7.191-15.401l31-2.635l4.42,4.599l-26.601,6.308l-3.877,11.475
                    c-0.007,0-60.087,125.604-51.263,187.05c0,0,37.502,22.37,74.988,0l9.962-168v-13.984l55.8-12.588l-3.941,9.705l-41.604,13.529
                    l19.242,6.874l236.203,72.7l236.201-72.7l159.699-57.116l-395.9-152.313L0,318.734z"/>
                </g>
              </svg>
            </div>

            <div className="title-feature text-center">For Students</div>

            <div className="text-center">Get all the math you need for Data Science in one place.</div>
          </Col>

        </Row>
      </Container>
    );
  }
}



export default ForWho;
