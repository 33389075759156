import React from 'react';

import Punchline from './Punchline.js'
import LookInside from './LookInside.js'
import Offer from './Offer.js'
import Satisfied from './Satisfied.js'
import Subscribe from './Subscribe.js'
import ForWho from './ForWho.js'
import WhyMath from './WhyMath.js'
import MathTopics from './MathTopics.js'
import MathTopicsBullets from './MathTopicsBullets.js'
import InsideBook from './InsideBook.js'
import Toc from './Toc.js'
import Author from './Author.js'
import FAQ from './FAQ.js'
import Footer from './Footer.js'


class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <Punchline />
        <LookInside />
        <Offer />
        <Satisfied />
        <ForWho />
        <WhyMath />
        <MathTopics />
        <MathTopicsBullets />
        <InsideBook />
        <Toc />
        <Subscribe />
        <Author />
        <FAQ />
        <Footer />
      </div>
    );
  }
}

export default Home;
