import React from 'react';

import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import ExpiredURL from './ExpiredURL.js'
import Wait from './Wait.js'


class PaymentSucess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      url: this.props.location.search.slice(5),
    }

    const urlIsValid = this.checkURLValidity(this.state.url);
  }
  async checkURLValidity(url) {
    const response = await fetch(url, { method: 'GET' }).then(response => {
        this.setState({status: response.status});
    }).catch(function(err) {
      console.log('err', err);
    });
  }

  render() {
    const { status } = this.state;

    if (status === null) {
      return (
        <Wait />
        );
    }
    else if (status === 403) {
      return (
        <ExpiredURL />
      )
    } else if (status === 200) {
      return (
        <Container>
          <div class="payment-success">
            Thanks for your order
            You can download Essential Math for data Science <a href= { this.state.url }>here</a>
          </div>
        </Container>
      );
    }

  }
}



export default PaymentSucess;
