import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ipad from '../assets/images/ipad.png';
import phone from '../assets/images/phone.png';
import mac from '../assets/images/mac.jpg';
import jupyter from '../assets/images/jupyter.svg';
import updates from '../assets/images/updates.svg';


class InsideBook extends React.Component {
  render() {
    return (
		<div className="inside-book" width="20%">
			<Container>
				<h2 className="underline">Inside the Book</h2>

					<div className="inside-book-description">
						<p>If you enter the field of data science and machine learning without a math background
      					you might feel overwhelmed by the underlying concepts.</p>

      					<p>It is hard to find ressources that target exactly the math you'll need in data science
      					and machine learning: you don't want to become a mathematician but better understand
      					the concepts of data science.</p>

      					<p>In <it>Essential Math for Data Science</it>, I emphasize intuition over proofs and
      					theorems. It is why visualizations and code are so useful in this context.</p>
      				</div>
	        	<Row style={{marginBottom: '4rem'}} className="align-self-center">
	        		<Col xs={{span: 12, order: 'last'}} sm={8} className="align-self-center" style={{marginTop: "2rem"}}>

	        			<span className="inline-title">
      						Inside.
      					</span> The book is designed to help you learn using code, visualizations and practical examples.
      					The purpose is to give insights instead of proof and theorems. You'll see that code is
      					a great way to experiment and gain more intuition about the theory.
      				</Col>
      				<Col xs={12} sm={4} className="align-self-center features" style={{marginTop: "2rem"}}>
	      				<div className="features-block">
		          			<div>
			          			<svg style={{marginRight: "1rem"}} width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#2EBCE7" xmlns="http://www.w3.org/2000/svg">
				                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
				                </svg>
			          			<span style={{color: "#f87188", fontWeight: 500}}>400+</span> pages
		          			</div>
		          			<div>
			          			<svg style={{marginRight: "1rem"}} width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#2EBCE7" xmlns="http://www.w3.org/2000/svg">
				                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
				                </svg>
			          			<span style={{color: "#f87188", fontWeight: 500}}>200+</span> figures
		          			</div>
		          			<div>
			          			<svg style={{marginRight: "1rem"}} width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#2EBCE7" xmlns="http://www.w3.org/2000/svg">
				                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
				                </svg>
			          			<span style={{color: "#f87188", fontWeight: 500}}>300+</span> code blocks
		          			</div>
		          			<div>
			          			<svg style={{marginRight: "1rem"}} width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#2EBCE7" xmlns="http://www.w3.org/2000/svg">
				                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
				                </svg>
			          			<span style={{color: "#f87188", fontWeight: 500}}>10</span> hands-on projects
		          			</div>
	          			</div>
					</Col>
				</Row>

	        	<Row style={{marginBottom: '4rem'}}>

					<Col xs={12} sm={4} className="text-center align-self-center" style={{marginTop: "2rem"}}>
      					<img src={phone} width="35%" alt="profile"  />
      					<img src={ipad} width="50%" alt="profile" />
      				</Col>

					<Col xs={12} sm={8} className="align-self-center" style={{marginTop: "2rem"}}>
      					<span className="inline-title">
      						Readers.
      					</span> You'll get the book in various versions (PDF/EPUB) with no DRM (Digital Rights Management)
      					allowing you to read it anywhere.
      					Since most E-readers don't support Mathjax or Mathml, the equations have been converted
      					to images. Feel free to ask for a specific version compatible with your E-reader. I also
      					recommend to read the book in color for the illustrations.
      				</Col>

				</Row>

				<Row style={{marginBottom: '4rem'}}>
					<Col xs={{span: 12, order: 'last'}} sm={8} className="align-self-center" style={{marginTop: "2rem"}}>
      					<span className="inline-title">
      						Notebooks.
      					</span> If you choose to get the complete code, you'll also have access to the notebooks
      					containing all the code. This is a great way to run the code while you read the
      					book and be sure that you follow along and get all the steps (e.g. check the shape of the matrices,
      					do interactive plots in the notebook, etc.).
      				</Col>
      				<Col xs={12} sm={2} className="text-center align-self-center" style={{margin: '2rem auto auto auto'}}>
      					<img src={jupyter} className="inside-book-icon" alt="jupyter" />
      				</Col>
				</Row>

				<Row style={{marginBottom: '4rem'}}>
					<Col xs={12}  sm={4} className="align-self-center" style={{marginTop: "2rem"}}>
						<img src={mac} width="100%" alt="profile" />
					</Col>
					<Col xs={12} sm={8} className="align-self-center" style={{marginTop: "2rem"}}>
      					<span className="inline-title">
      						Hands-on.
      					</span> You'll find one hands-on project at the end of each chapter. The goal is to show
      					you how the math concepts relates to practical applications and to illustrate the
      					theoretical notions through examples.
      				</Col>
				</Row>


				<Row>
					<Col xs={{span: 12, order: 'last'}} sm={8} className="align-self-center" style={{marginTop: "2rem"}}>
      					<span className="inline-title">
      						Updates.
      					</span> You'll get all the next releases and updates of the book and the associated content.
      				</Col>
      				<Col xs={12}  sm={2} className="text-center align-self-center" style={{margin: '2rem auto auto auto'}}>
						<img src={updates} className="inside-book-icon" alt="profile" />
					</Col>
				</Row>














			</Container>
		</div>
    );
  }
}



export default InsideBook;




