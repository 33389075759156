import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Container from 'react-bootstrap/Container'

import profile from '../assets/images/profile.jpg';


class Author extends React.Component {
  render() {
    return (
      	<div className="author">
	      	<Container>
		            <h2 className="author-title text-center">About the Author</h2>
	      		<Row>
		            <Col xs={12} sm={3} className="text-center align-self-center">
		                <img src={profile} width="100%" alt="profile" className="author-img"/>
		            </Col>
		            <Col xs={12} sm={9} className="align-self-center author-text">
		                <p>Hadrien Jean owns a <b>Ph.D</b> in cognitive science and currently works as a
		                <b> machine learning scientist</b> at iAudiogram (My Medical Assistant SAS).</p>

		                <p>He wrote a <b>series of tutorials</b> as notes of
		                the Deep Learning Book from Ian Goodfellow helping thousands of people
		                to learn math for machine learning.</p>

						<p>He's also working on speech processing and leads projects on
						<b> biodiversity assessment</b> using deep learning applied to audio recordings.</p>

						<p>He concurrently teaches <b>machine learning</b> and
						<b> deep learning</b> in data science bootcamps at Le Wagon.</p>
		            </Col>
	            </Row>
			</Container>
		</div>
    );
  }
}


export default Author;
