import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import integral from '../assets/images/integral.svg';
import gaussian from '../assets/images/gaussian.svg';
import linear_algebra from '../assets/images/linear_algebra.svg';


class MathTopicsBullets extends React.Component {
  render() {
    return (
      <Container className="math-topics-bullets">
        <Row>
          <Col xs={12} sm={4}>
            <div className="text-center" style={{margin: "1rem auto"}}>
              <img src={integral} alt="" width="20px" title="integral" />
            </div>
            <div className="title-feature text-center">
              Calculus
            </div>
            <div className="text-center">
              Learn about the two core Calculus concepts: derivatives and integrals.
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <div className="text-center" style={{margin: "1rem auto", padding: "0.5rem 0"}}>
              <img src={gaussian} alt="" width="30px" title="gaussian" />
            </div>
            <div className="title-feature text-center">
              Statistics and Probability
            </div>
            <div className="text-center">
              From probability distributions to bayesian statistics and information theory.
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <div className="text-center" style={{margin: "0.6rem auto"}}>
              <img src={linear_algebra} alt="" width="50px" title="linear_algebra" />
            </div>
            <div className="title-feature text-center">
              Linear Algebra
            </div>
            <div className="text-center">
              From scalars and vectors to
              eigendecomposition and Singular Value Decomposition.
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}



export default MathTopicsBullets;
