import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Cover from './Cover.js'
import Gems from './Gems.js'


class Punchline extends React.Component {
  render() {
    return (
      <Container>
        <div id="punchline" className="punchline">
          <Row className="align-self-center">
            <Col xs={{span: 12, order: 'last'}} md={{span: 7, order: 'first'}} className="align-self-center col-8-sm">
                <h1 className="mb-5">Learn <span className="highlight">mathematics</span> for data science and
                  machine learning.</h1>
                <p className="punchline-subtext">
                  Improve your skills by learning using code and visualizations.
                </p>
                <Gems />
            </Col>
            <Col md={5} style={{margin: "auto", padding: 0}}>
                <Cover/>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}




export default Punchline;
