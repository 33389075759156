import React from 'react';

import Container from 'react-bootstrap/Container'


class ExpiredURL extends React.Component {
  render() {
    return (
      <Container>
        <div class="expired-url text-center">
          <h3>Expired Link</h3>
          <p>This link has expired. Contact us at</p>
          <p>contact@essentialmathfordatascience.com</p>
          <p>to get a new one.</p>
        </div>
      </Container>
    );
  }
}



export default ExpiredURL;
