import React from 'react';

import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import ExpiredURL from './ExpiredURL.js'


class Wait extends React.Component {
  render() {
  	return (
	  	<Container>
		  	<div class="wait">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		</Container>
	)
  }
}


export default Wait;


