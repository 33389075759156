import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'

import ch02_integrals_segments_non_linear from '../assets/images/toc/ch02_integrals_segments_non_linear.png';
import ch03_joint_distribution_10_slices_density from '../assets/images/toc/ch03_joint_distribution_10_slices_density.png';
import output_ch04_16_0 from '../assets/images/toc/output_ch04_16_0.png';
import output_ch05_34_0 from '../assets/images/toc/output_ch05_34_0.png';
import output_ch06_139_0 from '../assets/images/toc/output_ch06_139_0.png';
import ch07_scalars_to_tensors from '../assets/images/toc/ch07_scalars_to_tensors.png';
import output_ch10_113_0 from '../assets/images/toc/output_ch10_113_0.png';
import projection_in_column_space_with_b from '../assets/images/toc/projection_in_column_space_with_b.png';
import linear_combination_two_vectors from '../assets/images/toc/linear_combination_two_vectors.png';
import ch09_SVD_geometry from '../assets/images/toc/ch09_SVD_geometry.png';


class Toc extends React.Component {
  render() {
    return (
    	<Container id="toc" className="toc">
    		<h2 className="underline">Table of Contents</h2>
    		<div className="toc-description">
    		Explore the complete table of contents to see exactly what's inside the book. You'll find here all the
    		core concept needed for data science and machine learning.
    		</div>

    		<div>
				<h3 className="toc-part">PART 1. Calculus</h3>
				<div className="toc-part-description">
				In this first chapter, you'll see the basics of calculus: derivatives and integrals.
				These notions are important to understand core machine learning concepts like
				gradient descent and model performance estimation (area under the curve).
				</div>
    			<Row>
    			<Col className="text-center">
						<img src={ch02_integrals_segments_non_linear} width="20%" alt="Area under the curve"
					    	title="Area under the curve" />
					</Col>
    				<Col xs={12}>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
							    	<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch01. Calculus: Derivatives and Integrals</h4>
							    </Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>1.1 Derivatives</div>
												<div>1.2 Integrals And Area Under The Curve</div>
											    <div>1.3 <strong>Hands-On Project</strong>: Gradient Descent</div>

									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>

				</Row>
				<h3 className="toc-part">PART 2. Statistics and Probability</h3>
				<div className="toc-part-description">
				To make sense of data, you have to deal with the uncertainty coming the data itself, from data
				that you don't have, or from the inherent stochasticity of your system. The goal of
				statistics and probability is to provide a framework to deal with this uncertainty.
				</div>
				<Row>
				<Col className="text-center align-self-center">
    					<img className="toc-img" src={output_ch04_16_0} width="20%" alt="Gaussian Distributions" title="Gaussian Distributions" />
					</Col>
    				<Col xs={12}>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
								    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch02. Statistics and Probability Theory</h4>
							    </Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
									    		<div>2.1 Descriptive Statistics</div>
									   			<div>2.2 Random Variables</div>
												<div>2.3 Probability Distributions</div>
												<div>2.4 Joint, Marginal, and Conditional Probability</div>
												<div>2.5 Cumulative Distribution Functions</div>
												<div>2.6 Expectation and Variance of Random Variables</div>
												<div>2.7 <strong>Hands-On Project</strong>: The Central Limit Theorem</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch03. Common Probability Distributions</h4>
						    	</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>3.1 Uniform Distribution</div>
												<div>3.2 Gaussian distribution</div>
												<div>3.3 Bernoulli Distribution</div>
												<div>3.4 Binomial Distribution</div>
												<div>3.5 Poisson Distribution</div>
												<div>3.6 Exponential Distribution</div>
												<div>3.7 <strong>Hands-on Project</strong>: Waiting for the Bus</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch04. Bayesian Statistics and Information Theory</h4>
							    </Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>4.1 Bayes’ Theorem</div>
												<div>4.2 Likelihood</div>
												<div>4.3 Information Theory</div>
												<div>4.4 <strong>Hands-On Project</strong>: Bayesian Inference</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>

				</Row>
				<h3 className="toc-part">PART 3. Linear Algebra</h3>
				<div className="toc-part-description">
				Linear algebra is a central topic in data science and machine learning. You'll learn in these
				chapters the major concepts of vector spaces that you'll need to understand machine learning
				algorithms more deeply. We'll start from the basics of vectors and matrices and finish with matrix decomposition
				like Eigendecomposition and Singular Value Decomposition.
				</div>
				<Row>
				<Col className="text-center align-self-center">
						<img className="toc-img" src={output_ch06_139_0} width="50%" alt="L1 Regularization. Effect of Lambda." title="L1 Regularization. Effect of alpha." />
					</Col>
    				<Col xs={12}>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch05. Scalars and Vectors</h4>
						    	</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>5.1 What Vectors are?</div>
												<div>5.2 Operations and Manipulations on Vectors</div>
												<div>5.3 Norms</div>
												<div>5.4 The Dot Product</div>
												<div>5.5 <strong>Hands-on Project</strong>: Regularization</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch06. Matrices and Tensors</h4>
						    	</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>6.1 Introduction</div>
												<div>6.2 Operations and Manipulations on Matrices</div>
												<div>6.3 Matrix Product</div>
												<div>6.4 Special Matrices</div>
												<div>6.5 <strong>Hands-on Project</strong>: Image Classifier</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch07. Span, Linear Dependency, and Space Transformation</h4>
						    	</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>7.1 Linear Transformations</div>
												<div>7.2 Linear combination</div>
												<div>7.3 Subspaces</div>
												<div>7.4 Linear dependency</div>
												<div>7.5 Basis</div>
												<div>7.6 Special Characteristics</div>
												<div>7.7 <strong>Hands-On Project</strong>: Span</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch08. Systems of Linear Equations</h4>
						    	</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>8.1 System of linear equations</div>
												<div>8.2 System Shape</div>
												<div>8.3 Projections</div>
												<div>8.4 <strong>Hands-on Project</strong>: Linear Regression Using Least Approximation</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch09. Eigenvectors and Eigenvalues</h4>
						    	</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
												<div>9.1 Eigenvectors and Linear Transformations</div>
												<div>9.2 Change of Basis</div>
												<div>9.3 Linear Transformations in Different Bases</div>
												<div>9.4 Eigendecomposition</div>
												<div>9.5 <strong>Hands-On Project</strong>: Principal Component Analysis</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Accordion defaultActiveKey="1">
							<Card className="toc-card-clickable">
								<Accordion.Toggle as={Card.Header} eventKey="0" className="toc-card-header">
									<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									  <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
									</svg>
							    	<h4 className="toc-chapter">Ch10. Singular Value Decomposition</h4>
							    </Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="toc-sections">
						    					<div>10.1 Nonsquare Matrices</div>
						    					<div>10.2 Expression of the SVD</div>
						    					<div>10.3 Geometry of the SVD</div>
						    					<div>10.4 Low-Rank Matrix Approximation</div>
												<div>10.5 <strong>Hands-On Project</strong>: Image Compression</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
			</div>
		</Container>
    );
  }
}



export default Toc;
