import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Container from 'react-bootstrap/Container'

import profile from '../assets/images/profile.jpg';


class Story extends React.Component {
  render() {
    return (
      	<div className="story">
	      	<Container>
	      		<h2 className='underline'>Frequently Asked Questions</h2>


	      		<h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	What are the pre-requisites in terms of math and code to make the most of this book?
                </h3>
      			<div className="faq-answer">
	      			<p>
		               	In terms of math, the book is designed for people without a deep math background. However, you should have
		               	some knowledge about basic algebra. For instance, I consider that you understand what
		               	equations and mathematical variables are.
	                </p>
	                <p>
	                	In terms of code, the practical aspect of this book leverages the use of code to help you gain insights and mathematical
	                	intuition. If you don't have any experience in programming, you'll have trouble to
	                	follow along the examples. You could still skip these sections and focus on the plots and text but
	                	it is better if you can understand a bit of Python and Numpy.
	               	</p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	What will I find in the book that is not on your blog?
                </h3>
      			<div className="faq-answer">
	      			<p>
		                I will share around 25% of the book as excerpts on my blog. The goal is to allow readers to
		                check if the book is a fit or not.
	                </p>
	            </div>




	      		<h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	I found another book called "Essential Math for Data Science" on Amazon.
      				Is it the same book?
                </h3>
      			<div className="faq-answer">
	      			<p>
		                Yes. I started this book with the publisher O'Reilly but our paths diverged and the project
		                aborted. I wanted this book to approach the theoretical concepts needed for data science.
		                The good reviews by readers who got the early release convinced me to publish it by myself.
	                </p>
	                <p>
	      				Due to delays in the update of their references, some book retailers like Amazon (e.g. Amazon France)
	      				still propose to pre-order "Essential Math for Data Science" even if it will not be
	      				available. I'm very sorry about this confusion.
	      			</p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	Can I get help if I have trouble to understand the materials?
                </h3>
      			<div className="faq-answer">
	                <p>
		                Yes, if you buy the "Complete Code" version you get access to the private Github repository
		                and you'll be able to ask any questions as issues. I'll be here and do my best to help you and assist
		                in your learning path.
		            </p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	Will you release a paperback version?
                </h3>
      			<div className="faq-answer">
	                <p>
		                As for now, there is no current plan to do a paperback version of the book.
		                However, tell me (contact@essentialmathfordatascience.com) if this is something you would like to have and I'll
		                reconsider the option if many people are interested.
		            </p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	In what extents the content relates to data science and machine learning?
                </h3>
      			<div className="faq-answer">
	                <p>
	                	The math that you need for data science and machine learning have been carefully selected in
	                	"Essential Math for Data Science". However, the goal of the book is not to explain the
	                	machine learning algorithms themselves but to introduce you to the math you'll need to
	                	understand them. These math topics are quite general but the approach is focused on data science:
	                	e.g. the hands-on projects at the end of each chapter, the practical examples, the visualizations, etc.
		            </p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	Will I get the next updated versions for free?
                </h3>
      			<div className="faq-answer">
	                <p>
		                Yes. You'll get access to the project anytime (the book that you can download on Gumroad
		                and the notebooks on the private Github repository) and you'll get any future updates.
		            </p>
	            </div>





	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	Is the payment secure?
                </h3>
      			<div className="faq-answer">
	                <p>
		                Yes. I use Gumroad to process the payment and deliver the book and the access to the Github
		                repo (if you buy the Complete Code version).
		            </p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	Do I need to have a Github account to buy the Complete Code version?
                </h3>
      			<div className="faq-answer">
	                <p>
		                Yes. With the Complete Code version, you'll get access to a private Github repository
		                where you'll find the book under the form of Jupyter notebooks. These notebooks (one per chapter)
		                contain the whole code and the whole text of the book. To get the access, you'll need a Github
		                account. Be sure to provide your right Github id (for instance, mine is "hadrienj") in the purchase form
		                (and not the email address). If you don't have a Github account, you can create one easily!
		            </p>
	            </div>




	            <h3 className="gems-text faq-question" style={{textTransform: 'uppercase'}}>
                	What is Haliotis-Publishing?
                </h3>
      			<div className="faq-answer">
	                <p>
		                I wrote this book as Jupyter Notebooks and found how it is useful to have text and code
		                blocks with outputs like plots. I thus developed a set of tools to convert notebooks into
		                PDF and ebooks and founded Haliotis-Publishing to allow people to write well formated content
		                using notebooks.
		            </p>
	            </div>




			</Container>
		</div>
    );
  }
}


export default Story;
