import React, { useState } from 'react';

import Carousel from 'react-bootstrap/Carousel'
import Modal from 'react-bootstrap/Modal'


import ex_page_1 from '../assets/images/ex_page_1.png';
import ex_page_2 from '../assets/images/ex_page_2.png';
import ex_page_3 from '../assets/images/ex_page_3.png';
import ex_page_4 from '../assets/images/ex_page_4.png';
import ex_page_5 from '../assets/images/ex_page_5.png';
import ex_page_6 from '../assets/images/ex_page_6.png';


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

        <LookInside />
    </Modal>
  );
}


function LookInside() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
      <Carousel style={{width: '100%', margin: 'auto'}} activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item className="text-center">
          <img
            className="d-block w-100"
            src={ex_page_1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={ex_page_2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={ex_page_3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <img
            className="d-block w-100"
            src={ex_page_4}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={ex_page_5}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={ex_page_6}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
  );
}



export default MyVerticallyCenteredModal;
