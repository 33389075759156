import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './components/Home.js';
import NavBarHeader from './components/NavBarHeader.js';
import Offer from './components/Offer.js';
import PaymentSuccess from './components/PaymentSuccess.js';


function App() {
  return (
    <Router>
        <NavBarHeader />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/get" component={Offer} />
          <Route exact path="/payment-success" component={PaymentSuccess} />
        </Switch>
    </Router>
  );
}

export default App;
