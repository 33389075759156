import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <div id="footer" className="footer text-center">
          contact@essentialmathfordatascience.com
      </div>
    );
  }
}


export default Footer;
