import React from 'react';

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import {Link} from "react-router-dom";


class NavBarHeader extends React.Component {
  render() {
    return (
      <Navbar className="nav-title">
      <div className="nav-background"></div>
        <Navbar.Brand className="navbar-brand">
          <Link style={{color: 'white'}} to="/">Essential Math for Data Science | The book</Link>
        </Navbar.Brand>

      </Navbar>
    );
  }
}

export default NavBarHeader;
