import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import star from '../assets/images/icon_star.svg';


class Gems extends React.Component {
  render() {
    return (
      <div className="gems">
        <Row>
          <Col xs={12} sm={6} className="gems-item">
            <Row>
              <Col xs={1} className="star-gems">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#56587233" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
              </Col>
              <Col xs={10} style={{margin: '0 0.3rem'}}>
                <div className="gems-text">Learn with <span className="bold">code</span></div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6} className="gems-item">
            <Row>
              <Col xs={1} className="star-gems">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#56587233" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
              </Col>
              <Col xs={10} style={{margin: '0 0.3rem'}}>
                <div className="gems-text">Practical <span className="bold">examples</span></div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="gems-item">
            <Row>
              <Col xs={1} className="star-gems">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#56587233" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
              </Col>
              <Col xs={10} style={{margin: '0 0.3rem'}}>
                <div className="gems-text"><span className="bold">Visualizations</span> to get more insights</div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6} className="gems-item">
            <Row>
              <Col xs={1} className="star-gems">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="#56587233" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
              </Col>
              <Col xs={10} style={{margin: '0 0.3rem'}}>
                <div className="gems-text">No math background? We start from the <span className="bold">basics</span>.</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}




export default Gems;
