import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import integral from '../assets/images/integral.svg';
import gaussian from '../assets/images/gaussian.svg';
import linear_algebra from '../assets/images/linear_algebra.svg';


class MathTopics extends React.Component {
  render() {
    return (
      <Container className="math-topics">
        <h2 className="underline">Learn the Math You'll Need</h2>
        <div className="math-topics-description">
          <p>It is crucial for your learning path to target the math concepts
          that you'll use in data science and machine learning.
          The topics selected in this book will give you exactly that. And at the right level of
          detail.</p>
          <p>If you try to read math text books about topics like linear algebra,
          you might find it difficult and not so motivating because the level of detail is not
          necessarily suited to data science and machine learning.</p>
        </div>
      </Container>
    );
  }
}



export default MathTopics;
